import React from "react";
import { useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import "./guard.scss";
import html2pdf from "html2pdf.js";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGuard } from "store/action/guardAction";
import Alert from "components/Alert";
import { useEffect } from "react";

const GuardDetails = (props) => {
  const [redirectFlag, setRedirectFlag] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const form = useRef(null);
  const dispatch = useDispatch();
  const { guardReducer } = useSelector((state) => state);

  useEffect(() => {
    if (guardReducer.closeModal) {
      setRedirectFlag(true);
    }
  }, [guardReducer.closeModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData(form.current);
    if (formdata.get("profile_picture").name === "") {
      formdata.delete("profile_picture");
    }
    dispatch(
      updateGuard(props?.location?.state?.data.id, formdata, "formdata")
    );
    setIsEditing(false);
  };

  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const printFunction = async (e) => {
    var divContents = document.querySelector(".guard-details");
    var opt = {
      margin: 0.2,
      filename: "employee-profile.pdf",
      image: { type: "jpg", quality: 0.2 },
      html2canvas: { scale: 2, useCors: true },
      jsPDF: { unit: "in", format: "a3", orientation: "p" },
    };
    if(divContents.querySelector("IMG") && divContents.querySelector("IMG").src) {
      await toDataURL(divContents.querySelector("IMG").src, (dataUrl) => {
        divContents.querySelector("IMG").src = dataUrl;
        html2pdf().from(divContents).set(opt).save();
      });
    } else {
      html2pdf().from(divContents).set(opt).save();
    }
    
    // divContents.querySelector("IMG").src =
    //   props?.location?.state?.data.profile_picture;
  };
  return (
    <>
      {redirectFlag ? (
        <Redirect
          to={{
            pathname: "/employees",
          }}
        />
      ) : (
        <>
          <div className="guard-details-main">
            <Alert />
            <div className="d-flex justify-content-between button-section">
              <button
                className="btn btn-primary"
                onClick={() => setRedirectFlag(true)}
              >
                <i className="fa fa-arrow-left"></i>
                Back
              </button>
              <button
                className="btn btn-warning"
                onClick={(e) => {
                  printFunction(e);
                }}
              >
                <i className="fa fa-print"></i>
                Print
              </button>
              <button
                className="btn btn-primary"
                onClick={() =>
                  isEditing ? setIsEditing(false) : setIsEditing(true)
                }
              >
                <i className={isEditing ? "fa fa-close" : "fa fa-pencil"}></i>
                {isEditing ? "Cancel" : "Edit"}
              </button>
            </div>
            <form
              id="newGuardForm"
              ref={form}
              className="p-2"
              onSubmit={(e) => handleSubmit(e)}
            >
              {isEditing && (
                <button
                  type="submit"
                  className="btn btn-primary pull-right mt-2 me-2"
                >
                  <i className="fa fa-save"></i>
                </button>
              )}
              <div
                className="guard-details"
                style={{ border: "1px solid gray", padding: "10px" }}
              >
                {!isEditing ? (
                  <div
                    className=""
                    style={{
                      width: "200px",
                      minHeight: "250px",
                      float: "right",
                      margin: "10px",
                    }}
                  >
                    {props?.location?.state?.data.profile_picture ? (
                      <img
                        alt=""
                        className=""
                        style={{
                          width: "200px",
                          minHeight: "250px",
                          float: "right",
                        }}
                        src={props?.location?.state?.data.profile_picture}
                      />
                    ) : (
                      <div>No profile pic uploaded</div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "200px",
                      minHeight: "250px",
                      float: "right",
                      margin: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                    }}
                  >
                    <label>Upload profile pic:</label>
                    <input
                      type="file"
                      className="form-control"
                      id="profile_picture"
                      placeholder="Profile Picture"
                      name="profile_picture"
                    />
                  </div>
                )}
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Full Name:
                    </span>
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.full_name}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="full_name"
                        defaultValue={props?.location?.state?.data?.full_name}
                        placeholder="Full Name"
                        name="full_name"
                        required
                      />
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="dob" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Date Of Birth:
                    </span>
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.date_of_birth}</div>
                    ) : (
                      <input
                        type="date"
                        className="form-control"
                        id="date_of_birth"
                        defaultValue={
                          props?.location?.state?.data?.date_of_birth
                        }
                        placeholder="Date Of Birth"
                        name="date_of_birth"
                        required
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="phone" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Phone Number:
                    </span>
                    {/* </label>
                     */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.phone}</div>
                    ) : (
                      <input
                        type="number"
                        className="form-control"
                        id="phone"
                        defaultValue={props?.location?.state?.data?.phone}
                        placeholder="Phone Number"
                        name="phone"
                        required
                      />
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="email" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Email:
                    </span>
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.email}</div>
                    ) : (
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        defaultValue={props?.location?.state?.data?.email}
                        placeholder="Email"
                        name="email"
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="full_address" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Full Address: {/* </label> */}
                    </span>
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.full_address}</div>
                    ) : (
                      <textarea
                        className="form-control"
                        id="full_address"
                        defaultValue={
                          props?.location?.state?.data?.full_address
                        }
                        placeholder="Full Address"
                        name="full_address"
                        required
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="ni_number" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      NI Number:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.ni_number}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="ni_number"
                        defaultValue={props?.location?.state?.data?.ni_number}
                        placeholder="NI Number"
                        name="ni_number"
                      />
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="houry_rate" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Hourly Rate: {/* </label> */}
                    </span>
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.houry_rate}</div>
                    ) : (
                      <input
                        type="decimal"
                        className="form-control"
                        id="houry_rate"
                        defaultValue={props?.location?.state?.data?.houry_rate}
                        placeholder="Hourly Rate"
                        name="houry_rate"
                        required
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="brp_number" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Passport / BRP Number:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.brp_number}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="brp_number"
                        defaultValue={props?.location?.state?.data?.brp_number}
                        placeholder="BRP Numner"
                        name="brp_number"
                        required
                      />
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="brp_expiry" className="font-weight-bold"> */}
                    Expiry:
                    {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.brp_expiry}</div>
                    ) : (
                      <input
                        type="date"
                        className="form-control"
                        id="brp_expiry"
                        defaultValue={props?.location?.state?.data?.brp_expiry}
                        placeholder="BRP Date"
                        name="brp_expiry"
                        required
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label
                    htmlFor="personal_id_number"
                    className="font-weight-bold"
                  > */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      SIA badge no.:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.sia_static_door}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="sia_static_door"
                        defaultValue={
                          props?.location?.state?.data?.sia_static_door
                        }
                        placeholder=" SIA badge no."
                        name="sia_static_door"
                        required
                      />
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="sia_expiry" className="font-weight-bold"> */}
                    Expiry: {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.sia_expiry}</div>
                    ) : (
                      <input
                        type="date"
                        className="form-control"
                        id="sia_expiry"
                        defaultValue={props?.location?.state?.data?.sia_expiry}
                        placeholder="Expity Date"
                        name="sia_expiry"
                        required
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="sia_cctv" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Other licence no.1: {/* </label> */}
                    </span>
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.sia_cctv}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="sia_cctv"
                        defaultValue={props?.location?.state?.data?.sia_cctv}
                        placeholder="Other licence no.1"
                        name="sia_cctv"
                      />
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="cscs_license" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Other licence no.2:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.cscs_license}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="cscs_license"
                        defaultValue={
                          props?.location?.state?.data?.cscs_license
                        }
                        placeholder="Other licence no.2"
                        name="cscs_license"
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="work_locations" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Work Locations:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.work_locations}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="work_locations"
                        defaultValue={
                          props?.location?.state?.data?.work_locations
                        }
                        placeholder="Work Locations"
                        name="work_locations"
                        required
                      />
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="cctv_expiry" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      CCTV Expiry:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.cctv_expiry}</div>
                    ) : (
                      <input
                        type="date"
                        className="form-control"
                        id="cctv_expiry"
                        defaultValue={props?.location?.state?.data?.cctv_expiry}
                        placeholder="CCTV Date"
                        name="cctv_expiry"
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="guest" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Guard Type:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>
                        {props?.location?.state?.data?.guest
                          ? "Sub Contractor"
                          : "Permanent"}
                      </div>
                    ) : (
                      <select
                        type="text"
                        className="form-control"
                        id="guest"
                        placeholder="Guest"
                        name="guest"
                        defaultValue={props?.location?.state?.data?.guest}
                      >
                        <option value="0">--Select Guard Type--</option>
                        <option value="1">Sub Contractor</option>
                        <option value="0">Permanent</option>
                      </select>
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label
                    htmlFor="personal_id_number"
                    className="font-weight-bold"
                  > */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Personal Id Number:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>
                        {props?.location?.state?.data?.personal_id_number}
                      </div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="personal_id_number"
                        placeholder="PIN"
                        defaultValue={
                          props?.location?.state?.data?.personal_id_number
                        }
                        name="personal_id_number"
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label
                    htmlFor="employment_start"
                    className="font-weight-bold"
                  > */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Employment Start Date:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>
                        {props?.location?.state?.data?.employment_start}
                      </div>
                    ) : (
                      <input
                        type="date"
                        className="form-control"
                        id="employment_start"
                        placeholder="Start Date"
                        defaultValue={
                          props?.location?.state?.data?.employment_start
                        }
                        name="employment_start"
                        required
                      />
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label
                    htmlFor="employment_finish"
                    className="font-weight-bold"
                  > */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Employment Finish Date:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>
                        {props?.location?.state?.data?.employment_finish}
                      </div>
                    ) : (
                      <input
                        type="date"
                        className="form-control"
                        id="employment_finish"
                        defaultValue={
                          props?.location?.state?.data?.employment_finish
                        }
                        placeholder="Finish Date"
                        name="employment_finish"
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="account_number" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Name on account:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.name_on_account}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="name_on_account"
                        defaultValue={
                          props?.location?.state?.data?.name_on_account
                        }
                        placeholder="Name on account"
                        name="name_on_account"
                        required
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="account_number" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Account Number:
                    </span>
                    {/* </label> */}
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.account_number}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="account_number"
                        defaultValue={
                          props?.location?.state?.data?.account_number
                        }
                        placeholder="Account Number"
                        name="account_number"
                        required
                      />
                    )}
                  </div>
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                    }}
                  >
                    {/* <label htmlFor="sort_code" className="font-weight-bold"> */}
                    <span style={{ whiteSpace: "nowrap", marginRight: "3px" }}>
                      Sort Code: {/* </label> */}
                    </span>
                    {!isEditing ? (
                      <div>{props?.location?.state?.data?.sort_code}</div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="sort_code"
                        defaultValue={props?.location?.state?.data?.sort_code}
                        placeholder="Sort Rate"
                        name="sort_code"
                        required
                      />
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",

                    gap: "5px",
                  }}
                >
                  <div
                    className="  "
                    style={{
                      display: "flex",
                      paddingLeft: "5px",
                      alignItems: "center",
                      width: "100%",
                      border: `${
                        !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                      }`,
                      minHeight: "40px",
                      borderRadius: "3px",
                      color: "red",
                    }}
                  >
                    {/* <label htmlFor="ni_number" className="font-weight-bold"> */}
                    comments:
                    {!isEditing ? (
                      <div>
                        {props?.location?.state?.data?.comment || "none"}
                      </div>
                    ) : (
                      <textarea
                        className="form-control"
                        id="comment"
                        defaultValue={props?.location?.state?.data?.comment}
                        name="comment"
                      />
                    )}
                  </div>
                </div>
                <hr />
                <div>Documents:</div>
                <hr />
                {!isEditing ? (
                  props?.location?.state?.data?.docs?.map((d) =>
                    d?.doc ? (
                      <div className="doc-link">
                        <a href={d?.doc} target="_blank">
                          <span>{d?.doc_name}</span>
                        </a>
                      </div>
                    ) : (
                      ""
                    )
                  )
                ) : (
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",

                      gap: "5px",
                    }}
                  >
                    <div
                      className=" "
                      style={{
                        display: "flex",
                        paddingLeft: "5px",
                        alignItems: "center",
                        width: "100%",
                        border: `${
                          !isEditing ? "1px solid rgb(183, 174, 174)" : "none"
                        }`,
                        minHeight: "40px",
                        gap: "5px",
                        borderRadius: "3px",
                      }}
                    >
                      <label htmlFor="docs" className="font-weight-bold">
                        Documents
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="docs"
                        placeholder="docs"
                        name="docs"
                        multiple={true}
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};
export default GuardDetails;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "components/Modal";
import Grid from "components/DataTables";
import GuardForm from "./guardfrom";
import { getAllGuards, deleteGuard } from "store/action/guardAction";
import Alert from "components/Alert";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

const Guard = ({ getAllGuards, deleteGuard, guardReducer }) => {
  const [data, setData] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [activeEmployees, setActiveEmployees] = useState(false);
  const [activeEmployeeList, setActiveEmployeeList] = useState(
    guardReducer.guardList
  );

  useEffect(() => {
    if (!guardReducer.isGuardUpToDate) getAllGuards();
  }, [getAllGuards, guardReducer.isGuardUpToDate]);

  useEffect(() => {
    if (activeEmployees) {
      setActiveEmployeeList(
        guardReducer?.guardList?.filter((emp) => {
          if (emp?.employment_finish) {
            return new Date(emp.employment_finish) > new Date();
          } else {
            return true;
          }
        })
      );
    } else {
      setActiveEmployeeList(guardReducer?.guardList);
    }
  }, [activeEmployees, guardReducer?.guardList]);

  const actionRenderer = ({ data }) => {
    return (
      <>
        <button
          // data-bs-toggle="modal"
          // data-bs-target="#commonModal"
          className="btn fa fa-pencil-square ms-2"
          onClick={() => {
            setData(data);
            setIsUpdating(true);
          }}
        />
        <button
          className="btn fa fa-trash ms-2"
          onClick={() => deleteGuard(data.id)}
        />
      </>
    );
  };

  const picRenderer = ({ data }) => {
    return data.profile_picture ? "Yes" : "No";
  };

  const commentRenderer = ({ data }) => {
    return data.comment ? (
      <>
        <span
          className="comment-count"
          data-toggle="tooltip"
          title={data.comment}
        >
          <i className="fa fa-info-circle"></i>
        </span>
        <span>{data.full_name}</span>
      </>
    ) : (
      data.full_name
    );
  };

  const guardColumns = {
    columnDefs: [
      { headerName: "#", field: "id", sortable: true },
      {
        headerName: "Staff Name",
        // field: "full_name",
        cellRendererFramework: commentRenderer,
        sortable: true,
      },
      {
        headerName: "Pic",
        cellRendererFramework: picRenderer,
        width: 80,
      },
      { headerName: "SIA Number", field: "sia_static_door" },
      { headerName: "SIA Expires", field: "sia_expiry", width: 190 },
      { headerName: "Email", field: "email" },
      { headerName: "Phone", field: "phone" },
      {
        headerName: "Actions",
        cellRendererFramework: actionRenderer,
        width: 150,
        pinned: "right",
      },

      {
        headerName: "PIN",
        field: "personal_id_number",
      },
      {
        headerName: "Date Of Birth",
        field: "date_of_birth",
      },
      {
        headerName: "Full Address",
        field: "full_address",
      },
      { headerName: "Work Locations", field: "work_locations" },
      { headerName: "NI Number", field: "ni_number" },
      { headerName: "SIA CCTV", field: "sia_cctv" },
      { headerName: "CCTV Expiry", field: "cctv_expiry" },
      { headerName: "Passport Number", field: "brp_number" },
      { headerName: "Passport Expiry", field: "brp_expiry" },
      { headerName: "Hourly Rate", field: "houry_rate" },
      { headerName: "Employment Start", field: "employment_start" },
      { headerName: "Employment Finish", field: "employment_finish" },
      { headerName: "Sort Code", field: "sort_code" },
      { headerName: "Account Number", field: "account_number" },
      { headerName: "Name on account", field: "name_on_account" },
    ],
  };

  return (
    <>
      {isUpdating ? (
        <Redirect
          to={{
            pathname: "/guard-details",
            state: { data: data },
            setIsUpdating: setIsUpdating,
          }}
        />
      ) : (
        <>
          <Modal title={isUpdating ? "Update Employee" : "New Employee"}>
            <GuardForm data={data} isUpdating={isUpdating} />
          </Modal>
          <div className="col-xl-12 col-12">
            <Alert />
            <div className="card mt-3">
              <div className="card-header d-flex justify-content-between">
                <h4 className="text-center">Employees</h4>
                <div>
                  <span>active employees</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(e) => setActiveEmployees(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="card-body">
                <Grid
                  columnDefs={guardColumns}
                  rowData={activeEmployeeList}
                  dataRetrieved={!guardReducer.isRetrieving}
                >
                  <button
                    className="btn btn-primary fa fa-plus text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#commonModal"
                    onClick={() => {
                      setData({});
                      setIsUpdating(false);
                    }}
                  >
                    <span className="ms-2 text-white"> Add Employee </span>
                  </button>
                </Grid>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default connect(({ guardReducer }) => ({ guardReducer }), {
  getAllGuards,
  deleteGuard,
})(Guard);

import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  createShift,
  updateShift,
  getShiftById,
  getShiftLogs,
} from "store/action/shiftAction";
import { resetCloseModal } from "store/action/alerts";
import { getAllSites } from "store/action/siteAction";
import dayjs from "dayjs";
import Alert from "components/Alert";
import { Redirect } from "react-router-dom";
import Select from "react-select";

const ShiftForm = ({
  createShift,
  shiftReducer,
  resetCloseModal,
  getAllSites,
  siteReducer,
  getShiftById,
  getShiftLogs,
  clientReducer,
  updateShift,
  location,
}) => {
  const form = useRef(null);
  let close = useRef(null);
  const [repeatDays, setRepeatDays] = useState(0);
  const [repeatDaysArray, setrepeatDaysArray] = useState([]);
  const [startDat, setStartDate] = useState(dayjs());
  const [shiftBody, setShiftBody] = useState([]);
  const isupdating = location?.state?.isupdating;
  const [disable, setDisable] = useState(isupdating);
  const [cancelFlag, setCancelFlag] = useState(false);
  const [startFlag, setStartFlag] = useState(false);
  const [actualStart, setActualStart] = useState(null);
  const [finishFlag, setFinishFlag] = useState(false);
  const [actualFinish, setActualFinish] = useState(null);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState({
    value: 0,
    label: "Select",
  });

  const [body, setBody] = useState({});
  const dayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const site = location?.state?.site;
  const resetForms = () => {
    document.getElementById("shiftForm").reset();
    isupdating && setCancelFlag(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let finalBody = { ...body };
    finalBody["shifts"] = shiftBody;
    setBody(finalBody);
    const formdata = new FormData(form.current);
    shiftReducer.shiftDetails?.id
      ? updateShift(shiftReducer.shiftDetails.id, formdata, "formdata")
      : createShift(finalBody);
  };

  useEffect(() => {
    if (site) getShiftById(site);
  }, [getShiftById, site]);

  useEffect(() => {
    if (shiftReducer?.shiftDetails?.id) {
      getShiftLogs({ shift: shiftReducer?.shiftDetails?.id });
      setDefaultOption({
        value: shiftReducer?.shiftDetails?.site,
        label: shiftReducer?.shiftDetails?.site_name,
      });
    }
  }, [getShiftLogs, shiftReducer.shiftDetails.id]);

  useEffect(() => {
    if (!clientReducer.isClientUpToDate)
      getAllSites({ client: clientReducer.clientId });
  }, [getAllSites, clientReducer.isClientUpToDate, clientReducer.clientId]);

  useEffect(() => {
    if (!siteReducer.isSiteUpToDate) getAllSites();
  }, [getAllSites, siteReducer.isSiteUpToDate]);

  useEffect(() => {
    if (shiftReducer.closeModal) {
      close.click();
      resetForms();
      setrepeatDaysArray([]);
      setRepeatDays(0);
      resetCloseModal();
      isupdating && setDisable(true);
    }
  }, [shiftReducer.closeModal, resetCloseModal, isupdating]);

  useEffect(() => {
    siteReducer.siteList &&
      setOptions(
        siteReducer.siteList.map((obj) => ({
          value: obj.id,
          label: obj.site_name,
        }))
      );
  }, [siteReducer.siteList]);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    let subBody = { ...body };
    subBody[name] = value;
    setBody(subBody);
  };

  const handleSiteChange = (selected) => {
    let subBody = { ...body };
    subBody["site"] = selected.value;
    setBody(subBody);
    setDefaultOption(selected);
  };

  const changeActualStartTime = (e) => {
    setStartFlag(true);
    setActualStart(e.target.value);
  };

  const changeActualFinishTime = (e) => {
    setFinishFlag(true);
    setActualFinish(e.target.value);
  };

  const changeRepeat = (e) => {
    setRepeatDays(e.target.value);
    handleOnChange(e);
    let testArray = [];
    let shiftArray = [];
    for (let index = 0; index < e.target.value; index++) {
      testArray.push(index);
      shiftArray.push({
        start_date: startDat.add(index, "day").format("YYYY-MM-DD"),
        scheduled_start_time: document.getElementById("scheduled_start_time")
          .value,
        scheduled_finish_time: document.getElementById("scheduled_finish_time")
          .value,
        guards_required: document.getElementById("guards_required").value,
      });
      setShiftBody(shiftArray);
    }
    setrepeatDaysArray(testArray);
  };

  const changeShiftFields = (e, idx) => {
    const { name, value } = e.target;
    let cloneArray = [...shiftBody];
    cloneArray[idx][name] = value;
    setShiftBody(cloneArray);
  };

  const removeShiftObject = (idx) => {
    let cloneArray = [...shiftBody];
    let cloneRepeatArray = [...repeatDaysArray];
    cloneArray.splice(idx, 1);
    cloneRepeatArray.splice(idx, 1);
    setShiftBody(cloneArray);
    setrepeatDaysArray(cloneRepeatArray);
  };

  return cancelFlag ? (
    <Redirect
      to={{
        pathname: "/all-shifts",
        state: { dateToOpen: shiftReducer.shiftDetails.start_date },
      }}
    />
  ) : (
    <div className="col-12">
      {!isupdating && <Alert />}
      <form
        id="shiftForm"
        ref={form}
        className="p-2"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="form-row">
          {isupdating && (
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => setDisable(false)}
              >
                <i className="fa fa-pencil"></i>
                <span>Edit</span>
              </button>
            </div>
          )}
          <div className="form-group mt-2  ">
            <label htmlFor="name" className="font-weight-bold">
              Site Name <span className="req">*</span> :
            </label>
            <Suspense fallback={<div>Loading..</div>}>
              <Select
                name="site"
                id="site"
                onChange={(e) => handleSiteChange(e)}
                isDisabled={disable}
                required
                options={options}
                value={defaultOption}
              ></Select>
            </Suspense>
          </div>
          <div className="form-group mt-2  ">
            <label htmlFor="dob" className="font-weight-bold">
              Position <span className="req">*</span> :
            </label>
            <select
              type="text"
              className="form-control"
              id="position"
              onChange={(e) => handleOnChange(e, "position")}
              placeholder="Full Name"
              name="position"
              disabled={disable}
              required
            >
              {!isupdating && (
                <option value={0} key={0}>
                  --Select From The List --
                </option>
              )}
              {isupdating && (
                <option
                  value={shiftReducer.shiftDetails.position}
                  key={shiftReducer.shiftDetails.position}
                  selected
                >
                  {shiftReducer.shiftDetails.position}
                </option>
              )}
              <option value="Guard">Guard</option>
              <option value="Any">Any</option>
              <option value="Supervisor">Supervisor</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group mt-2  ">
            <label htmlFor="start_date" className="font-weight-bold">
              Start Date:
            </label>
            <input
              type="date"
              className="form-control"
              id="start_date"
              defaultValue={shiftReducer.shiftDetails.start_date}
              placeholder="Start Date"
              name="start_date"
              onChange={(e) => setStartDate(dayjs(e.target.value))}
              required
              disabled={disable}
            />
          </div>
          <div className="form-group mt-2  ">
            <label htmlFor="scheduled_start_time" className="font-weight-bold">
              Scheduled Start Time <span className="req">*</span> :
            </label>
            <input
              type="time"
              className="form-control"
              id="scheduled_start_time"
              defaultValue={shiftReducer.shiftDetails.scheduled_start_time}
              placeholder="Start Time"
              name="scheduled_start_time"
              required
              onChange={(e) => changeActualStartTime(e)}
              disabled={disable}
            />
          </div>
        </div>
        <div className="form-group mt-2  ">
          <label htmlFor="scheduled_finish_time" className="font-weight-bold">
            Scheduled Finish Time <span className="req">*</span> :
          </label>
          <input
            type="time"
            className="form-control"
            id="scheduled_finish_time"
            defaultValue={shiftReducer.shiftDetails.scheduled_finish_time}
            placeholder="Finish Time"
            name="scheduled_finish_time"
            onChange={(e) => changeActualFinishTime(e)}
            required
            disabled={disable}
          />
        </div>
        {isupdating && (
          <>
            <div className="form-group mt-2  ">
              <label
                htmlFor="scheduled_start_time"
                className="font-weight-bold"
              >
                Actual Start Time <span className="req">*</span> :
              </label>
              <input
                type="time"
                className="form-control"
                id="actual_start_time"
                defaultValue={
                  startFlag
                    ? actualStart
                    : shiftReducer.shiftDetails.actual_start_time
                }
                placeholder="Start Time"
                name="actual_start_time"
                disabled={disable}
              />
            </div>
            <div className="form-group mt-2  ">
              <label
                htmlFor="scheduled_finish_time"
                className="font-weight-bold"
              >
                Actual Finish Time <span className="req">*</span> :
              </label>
              <input
                type="time"
                className="form-control"
                id="actual_finish_time"
                defaultValue={
                  finishFlag
                    ? actualFinish
                    : shiftReducer.shiftDetails.actual_finish_time
                }
                placeholder="Finish Time"
                name="actual_finish_time"
                disabled={disable}
              />
            </div>
          </>
        )}
        <div className="form-row">
          <div className="form-group mt-2  ">
            <label htmlFor="shift_allowance" className="font-weight-bold">
              Shift Allowance :
            </label>
            <input
              type="number"
              className="form-control"
              id="shift_allowance"
              onChange={(e) => handleOnChange(e, "shift_allowance")}
              defaultValue={shiftReducer.shiftDetails.shift_allowance}
              placeholder="Shift Allowance"
              name="shift_allowance"
              disabled={disable}
            />
          </div>
          <div className="form-group mt-2  ">
            <label htmlFor="guards_required" className="font-weight-bold">
              Employees Required on each shift <span className="req">*</span> :
            </label>
            <input
              type="number"
              className="form-control"
              id="guards_required"
              defaultValue={shiftReducer.shiftDetails?.guards_required}
              placeholder="Emps Required"
              name="guards_required"
              required
              disabled={disable}
            />
          </div>
        </div>
        {!isupdating && (
          <div className="form-row">
            <div className="form-group mt-2  ">
              <label htmlFor="repeat_for" className="font-weight-bold">
                Repeat For Days:
              </label>
              <select
                type="text"
                className="form-control"
                id="repeat_for"
                defaultValue={shiftReducer.shiftDetails.repeat_for}
                onChange={(e) => changeRepeat(e)}
                placeholder="Repeat For"
                disabled={disable}
                name="repeat_for"
                required
              >
                <option>
                  {" "}
                  {shiftReducer?.shiftDetails?.repeat_for
                    ? shiftReducer?.shiftDetails?.repeat_for + " Days"
                    : "--select from the list--"}
                </option>

                <option value="1">Just one day</option>
                <option value="2">Two days</option>
                <option value="3">Three days</option>
                <option value="4">Four days</option>
                <option value="5">Five days</option>
                <option value="6">Six days</option>
                <option value="7">Seven days</option>
                <option value="8">Eight days</option>
                <option value="9">Nine days</option>
                <option value="10">Ten days</option>
                <option value="11">Eleven days</option>
                <option value="12">Twelve days</option>
                <option value="13">Thirteen days</option>
                <option value="14">Fourteen days</option>
                <option value="15">Fifteen days</option>
                <option value="16">Sixteen days</option>
                <option value="17">Seventeen days</option>
                <option value="18">Eighteen days</option>
                <option value="19">Nineteen days</option>
                <option value="20">Twenty days</option>
                <option value="21">twenty one days</option>
                <option value="22">Twenty Two days</option>
                <option value="23">Twenty Three days</option>
                <option value="24">Twenty Four days</option>
                <option value="25">Twenty Five days</option>
                <option value="26">Twenty Six days</option>
                <option value="27">Twenty Seven days</option>
                <option value="28">Twenty Eight days</option>
                <option value="29">Twenty Nine days</option>
                <option value="30">Thirty days</option>
              </select>
            </div>
            {repeatDays > 0 && (
              <div className="mt-2">
                <table className="table table-striped table-bordered">
                  <thead className="bg-primary">
                    <tr>
                      <th>Day</th>
                      <th>Date</th>
                      <th>Start Time</th>
                      <th>Finish Time</th>
                      <th>Guards Required</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {repeatDaysArray.length &&
                      repeatDaysArray.map((arr, i) => {
                        return (
                          <tr key={i}>
                            <td>{dayArray[startDat.add(arr, "day").day()]}</td>
                            <td>
                              {startDat.add(arr, "day").format("YYYY-MM-DD")}
                            </td>
                            <td>
                              <input
                                className="form-control"
                                name="scheduled_start_time"
                                id="scheduled_start_time"
                                type="time"
                                onChange={(e) => changeShiftFields(e, arr)}
                                defaultValue={
                                  document.getElementById(
                                    "scheduled_start_time"
                                  )?.value
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                name="scheduled_finish_time"
                                id="scheduled_finish_time"
                                type="time"
                                onChange={(e) => changeShiftFields(e, arr)}
                                defaultValue={
                                  document.getElementById(
                                    "scheduled_finish_time"
                                  )?.value
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                name="guards_required"
                                id="guards_required"
                                type="number"
                                onChange={(e) => changeShiftFields(e, arr)}
                                defaultValue={
                                  document.getElementById("guards_required")
                                    ?.value
                                }
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-danger"
                                id={arr}
                                onClick={(e) => removeShiftObject(arr)}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        <div className="form-group mt-2  ">
          <label htmlFor="ni_number" className="font-weight-bold">
            comments:
          </label>
          <textarea
            className="form-control"
            id="comment"
            onChange={(e) => handleOnChange(e, "comments")}
            defaultValue={shiftReducer.shiftDetails.comment}
            name="comment"
            disabled={disable}
          />
        </div>
        {location?.state?.isupdating && (
          <>
            <label htmlFor="history" className="">
              <b> Shift History:</b>
              <br />
            </label>
            <div className="form-group form-control mt-2  ">
              {shiftReducer?.shiftLogs.map((log, i) => {
                return (
                  <span className="text-capitalize" key={i}>
                    <b>
                      {" "}
                      {`${log.updated_by} updated the status from ${log.status_from} to ${log.status_to} ${log.updated_at}`}
                    </b>
                    <br />
                  </span>
                );
              })}
            </div>
          </>
        )}
        <div className="d-flex justify-content-center mt-2">
          <button type="submit" className="btn btn-primary" disabled={disable}>
            {shiftReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Processing...</span>
              </>
            ) : shiftReducer.shiftDetails.repeat_for ? (
              <span>Update Shift</span>
            ) : (
              <span>Save shift</span>
            )}
          </button>
          <button
            type="button"
            className="btn btn-warning ms-2"
            data-bs-dismiss="modal"
            ref={(button) => (close = button)}
            onClick={() => resetForms()}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
export default connect(
  ({ shiftReducer, siteReducer, clientReducer }) => ({
    shiftReducer,
    siteReducer,
    clientReducer,
  }),
  {
    createShift,
    resetCloseModal,
    getAllSites,
    updateShift,
    getShiftLogs,
    getShiftById,
  }
)(ShiftForm);

import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "screens/Dashboard";
import ProtectedRoute from "components/ProtetedRoutes";
import Login from "screens/Login/login";
import Guard from "screens/Guard";
import Shifts from "screens/Shifts";
import Site from "screens/Site";
import StaffShifts from "screens/Shifts/staffView";
import Clients from "screens/Clients";
import AllocateForm from "screens/Shifts/allocateForm";
import AssignedShift from "screens/AssignedShifts";
import ConfrimedShifts from "screens/ConfirmedShifts";
import ForgotPassword from "screens/ForgotPassword";
import User from "screens/Users/user";
import CancelledShifts from "screens/CancelledShifts";
import ResetPassword from "screens/ResetPassword";
import UnAssignedShifts from "screens/UnAssigned/index";
import ShiftForm from "screens/Shifts/shiftForm";
import ChangePassword from "screens/ChangePassword/changePassword";
import Communication from "screens/Communication";
import Payroll from "screens/Payroll";
import guestPayroll from "screens/Payroll/guestPayroll";
import Invoice from "screens/Invoice";
import Rota from "screens/Rota";
import GuardDetails from "screens/Guard/guard-details";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route
            exact
            path={`/reset-password/:token`}
            component={ResetPassword}
          />
          <ProtectedRoute>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/employees" component={Guard} />
            <Route path="/all-shifts" component={Shifts} />
            <Route path="/sites" component={Site} />
            <Route path="/clients" component={Clients} />
            <Route path="/allocate-form" component={AllocateForm} />
            <Route path="/assigned" component={AssignedShift} />
            <Route path="/confirmed" component={ConfrimedShifts} />
            <Route path="/cancelled" component={CancelledShifts} />
            <Route path="/unassigned" component={UnAssignedShifts} />
            <Route path="/staff-view-shifts" component={StaffShifts} />
            <Route path="/update-shift" component={ShiftForm} />
            <Route path="/communication" component={Communication} />
            <Route path="/payroll" component={Payroll} />
            <Route path="/rota" component={Rota} />
            <Route path="/guard-details" component={GuardDetails} />
            <Route path="/invoice" component={Invoice} />
            <Route path="/users" component={User} />
            <Route path="/guest-payroll" component={guestPayroll} />
          </ProtectedRoute>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

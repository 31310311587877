import Alert from "components/Alert";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { resetCloseModal } from "store/action/alerts";
import { sendEmail } from "store/action/guardAction";

const Communication = ({ sendEmail, guardReducer, resetCloseModal }) => {
  const form = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData(form.current);
    sendEmail(formdata, "formdata");
  };

  const resetForms = () => {
    document.getElementById("message").value = "";
  };

  useEffect(() => {
    if (guardReducer.closeModal) {
      resetForms();
      resetCloseModal();
    }
  }, [guardReducer.closeModal, resetCloseModal]);

  return (
    <div className=" col-lg-12">
      <Alert />
      <div className="text-center mt-3 mb-3">
        <h3>Welcome to the communication section</h3>
      </div>
      <div className="card">
        <div className="card-header text-center">
          <span>
            Please enter a message and click on send button to email all the
            active employees
          </span>
        </div>
        <div className="card-body">
          <form
            id="communicationForm"
            ref={form}
            className="p-2"
            onSubmit={(e) => handleSubmit(e)}
          >
            <textarea
              className="form-control"
              name="message"
              id="message"
              style={{ height: "300px" }}
            ></textarea>
            <div className="text-center mt-3">
              <button className="btn btn-primary" type="submit">
                {guardReducer.isRetrieving ? (
                  <>
                    <i className="fa fa-spinner fa-spin" />
                    <span> Processing...</span>
                  </>
                ) : (
                  <>
                    <i className="fa fa-paper-plane mr-2" />
                    <span> Send Email</span>
                  </>
                )}
              </button>
              <button
                className="btn btn-warning ms-2"
                type="button"
                onClick={(e) => resetForms()}
              >
                Clear
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(({ guardReducer }) => ({ guardReducer }), {
  sendEmail,
  resetCloseModal,
})(Communication);

import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import ShiftForm from "./shiftForm";
import { getAllShifts, setEvents } from "store/action/shiftAction";
import { connect } from "react-redux";
import ShiftStaffGrid from "components/ShiftGrid/staffView";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const StaffShifts = ({
  getAllShifts,
  clientReducer,
  shiftReducer,
  setEvents,
  location,
}) => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [mode, setMode] = useState(7);
  const [calendarView, setcalendarView] = useState(false);
  const [filterText, setFilterText] = useState("");
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const status = location.state.status;
  useEffect(() => {
    if (!shiftReducer.isShiftUpToDate)
      getAllShifts({
        start_date: currentDate.format("YYYY-MM-DD"),
        mode: mode,
        staff: 1,
        status: status,
      });
    setEvents([]);
  }, [
    getAllShifts,
    shiftReducer.isShiftUpToDate,
    currentDate,
    mode,
    setEvents,
    status,
  ]);

  useEffect(() => {
    getAllShifts({
      start_date: currentDate.format("YYYY-MM-DD"),
      mode: mode,
      staff: 1,
      status,
    });
  }, [currentDate, getAllShifts, mode, status]);

  useEffect(() => {
    if (!clientReducer.isClientUpToDate)
      getAllShifts({
        start_date: currentDate.format("YYYY-MM-DD"),
        mode: mode,
        staff: 1,
        client: clientReducer.clientId,
        status,
      });
  }, [
    getAllShifts,
    clientReducer.isClientUpToDate,
    clientReducer.clientId,
    currentDate,
    mode,
  ]);
  useEffect(() => {
    changeView();
  }, [mode]);

  const nextWeek = () => {
    let newDate = null;
    if (mode === 7) {
      newDate = currentDate.add(7, "day");
    } else {
      newDate = currentDate.add(
        currentDate.endOf("month").date() - 2 - currentDate.day(),
        "day"
      );
    }
    setCurrentDate(newDate);
  };

  const prevWeek = () => {
    let newDate = null;
    if (mode === 7) {
      newDate = currentDate.subtract(7, "day");
    } else {
      newDate = currentDate.subtract(
        currentDate.endOf("month").date() + currentDate.day() - 1,
        "day"
      );
    }
    setCurrentDate(newDate);
  };

  const changeView = () => {
    setcalendarView(!calendarView);
    getAllShifts({
      start_date: currentDate.format("YYYY-MM-DD"),
      mode: mode,
      staff: 1,
      status,
    });
  };

  return (
    <div className="col-12 card-body border">
      <div className="col-12 shift-header">
        <div className="d-flex justify-content-between border card-header  mb-2">
          <h3 className="search-box d-flex jystify-content-space-between ms-2 mt-2">
            staff View{" "}
            <div className="form-group">
              <input
                className="form-control"
                placeholder="employee name to search..."
                type="text"
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
              />
            </div>
          </h3>
          <Link
            to={status ? `/${status}` : "/all-shifts"}
            className="me-2 mt-2 btn btn-primary view-site-button"
          >
            <i className="fa fa-eye"></i>
            <span className="text-white">view site wise</span>
          </Link>
        </div>
        <div className="headerClass d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary changeDateButton me-2"
              onClick={() => prevWeek()}
            >
              <i className="fa fa-arrow-left"></i>
            </button>
            <button
              className="btn btn-primary changeDateButton  me-2"
              onClick={() => nextWeek()}
            >
              <i className="fa fa-arrow-right"></i>
            </button>
            <button
              className="btn btn-primary  text-white "
              data-bs-toggle="modal"
              data-bs-target="#commonModal"
            >
              Add Shift
            </button>
          </div>
          <span className="headerDate">
            <b>
              {dayArray[currentDate.day()]} - {currentDate.format("DD-MM-YYYY")}
            </b>
          </span>
          <button
            className="btn btn-primary text-white "
            onClick={() => {
              mode === 7 ? setMode(30) : setMode(7);
            }}
          >
            {calendarView ? "Month View" : "Week View"}
          </button>
        </div>

        <div style={{ marginTop: "20px" }}>
          <ShiftStaffGrid
            currentDate={currentDate}
            mode={mode}
            filterText={filterText}
          />
        </div>
        <Modal title="Add New Shift">
          <ShiftForm />
        </Modal>
      </div>
    </div>
  );
};

export default connect(
  ({ shiftReducer, clientReducer }) => ({ shiftReducer, clientReducer }),
  {
    getAllShifts,
    setEvents,
  }
)(StaffShifts);

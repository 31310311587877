import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "components/Modal";
import Grid from "components/DataTables";
import ClientForm from "./clientForm";
import { deleteClient, getAllClients } from "store/action/clientAction";
import Alert from "components/Alert";

const Client = ({ getAllClients, clientReducer, deleteClient }) => {
  const [data, setData] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!clientReducer.isClientUpToDate) getAllClients();
  }, [getAllClients, clientReducer.isClientUpToDate]);

  const actionRenderer = ({ data }) => {
    return (
      <>
        <button
          data-bs-toggle="modal"
          data-bs-target="#commonModal"
          className="btn fa fa-pencil-square ms-2"
          onClick={() => {
            setData(data);
            setIsUpdating(true);
          }}
        />
        <button
          className="btn fa fa-trash ms-2"
          onClick={() => deleteClient(data.id)}
        />
      </>
    );
  };

  const clientColumns = {
    columnDefs: [
      { headerName: "#", field: "id", sortable: true },
      {
        headerName: "Client Name",
        field: "name",
        sortable: true,
      },
      {
        headerName: "Contact Number",
        field: "contact_number",
      },
      {
        headerName: "Address",
        field: "address",
        sortable: true,
      },
      {
        headerName: "Contact Person",
        field: "contact_person",
        sortable: true,
      },
      {
        headerName: "Email",
        field: "email",
      },
      {
        headerName: "Actions",
        cellRendererFramework: actionRenderer,
        width: 100,
        pinned: "right",
      },
    ],
  };

  return (
    <>
      <Modal title={isUpdating ? "Update Client" : "New Client"}>
        <ClientForm data={data} isUpdating={isUpdating} />
      </Modal>

      <div className="col-xl-12 col-12">
        <Alert />
        <div className="card ">
          <div className="card-header">
            <h4 className="text-center">Clients</h4>
          </div>
          <div className="card-body">
            <Grid
              columnDefs={clientColumns}
              rowData={clientReducer.clientList}
              dataRetrieved={!clientReducer.isRetrieving}>
              <button
                className="btn btn-primary fa fa-plus text-white"
                data-bs-toggle="modal"
                data-bs-target="#commonModal"
                onClick={() => {
                  setData({});
                  setIsUpdating(false);
                }}>
                <span className="ms-2 text-white"> Add Client </span>
              </button>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(({ clientReducer }) => ({ clientReducer }), {
  getAllClients,
  deleteClient,
})(Client);

import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "components/Layout/index";

const ProtectedRoute = ({ userReducer, children }) => {
  const isAuthenticated = userReducer.isLoggedIn;
  if (!isAuthenticated) {
    return <Redirect to="/" />;
  } else {
    return <Layout>{children}</Layout>;
  }
};

export default connect(
  ({ userReducer }) => ({ userReducer }),
  {}
)(ProtectedRoute);

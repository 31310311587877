import Service from "api/service";

export const createShift = (body, contentType) => dispatch => {
  Service.post(
    dispatch,
    "shift/shifts/",
    body,
    {
      start: "CREATE_SHIFT",
      success: "CREATE_SHIFT_SUCCESS",
      failure: "CREATE_SHIFT_FAILED",
    },
    contentType
  );
};

export const updateShift = (id, body, contentType) => dispatch => {
  Service.patch(
    dispatch,
    `shift/shifts/${id}/`,
    body,
    {
      start: "UPDATE_SHIFT",
      success: "UPDATE_SHIFT_SUCCESS",
      failure: "UPDATE_SHIFT_FAILED",
    },
    contentType
  );
};

export const allocateShift = body => dispatch => {
  Service.put(dispatch, "shift/update/", body, {
    start: "ALLOCATE_SHIFT",
    success: "ALLOCATE_SHIFT_SUCCESS",
    failure: "ALLOCATE_SHIFT_FAILED",
  });
};

export const deleteShift = body => dispatch => {
  Service.delete(
    dispatch,
    "shift/update/",
    {
      start: "DELETE_SHIFT",
      success: "DELETE_SHIFT_SUCCESS",
      failure: "DELETE_SHIFT_FAILED",
    },
    body
  );
};

export const getAllShifts =
  (queryParams, action = "GET_ALL_SHIFTS") =>
  dispatch => {
    Service.get(
      dispatch,
      "shift/shifts/",
      {
        start: action,
        success: action + "_SUCCESS",
        failure: action + "_FAILED",
      },
      queryParams
    );
  };

export const getShiftById = id => dispatch => {
  Service.get(dispatch, `shift/shifts/${id}`, {
    start: "GET_SHIFT_BY_ID",
    success: "GET_SHIFT_BY_ID_SUCCESS",
    failure: "GET_SHIFT_BY_ID_FAILED",
  });
};

export const getShiftStats = queryParams => dispatch => {
  Service.get(
    dispatch,
    "shift/stats/",
    {
      start: "GET_SHIFT_STATS",
      success: "GET_SHIFT_STATS_SUCCESS",
      failure: "GET_SHIFT_STATS_FAILED",
    },
    queryParams
  );
};

export const getShiftLogs = queryParams => dispatch => {
  Service.get(
    dispatch,
    "shift/logs/",
    {
      start: "GET_SHIFT_LOGS",
      success: "GET_SHIFT_LOGS_SUCCESS",
      failure: "GET_SHIFT_LOGS_FAILED",
    },
    queryParams
  );
};

export const setEvents = body => dispatch => {
  Service.setEvents(dispatch, body, {
    start: "SET_EVENTS",
    success: "SET_EVENTS_SUCCESS",
    failure: "SET_EVENTS_FAILED",
  });
};

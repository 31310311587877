import { headers, baseUrl } from "./properties";

export default class Service {
  static async post(dispatch, url, body, types, contentType) {
    dispatch({ type: types.start });
    if (contentType !== "formdata") {
      body = JSON.stringify(body);
    }
    let response = await fetch(baseUrl() + url, {
      method: "POST",
      headers: headers(contentType),
      body: body,
    }).catch(() => {
      this.handleError(dispatch, types.failure);
    });

    this.dispatchResponse(dispatch, response, types);
  }

  static handleError(dispatch, failure) {
    dispatch({ type: "CONNECTION_REFUSED" });
    dispatch({
      type: failure,
      response: {
        data: "Something went wrong while performing the action. Please try again and if the problem persists contact system admin",
      },
    });
  }

  static async put(dispatch, url, body, types) {
    dispatch({ type: types.start });
    let response = await fetch(baseUrl() + url, {
      method: "PUT",
      headers: headers(),
      body: JSON.stringify(body),
    }).catch(() => {
      this.handleError(dispatch, types.failure);
    });

    this.dispatchResponse(dispatch, response, types);
  }

  static async patch(dispatch, url, body, types, contentType) {
    if (contentType !== "formdata") body = JSON.stringify(body);
    dispatch({ type: types.start });
    let response = await fetch(baseUrl() + url, {
      method: "PATCH",
      headers: headers(contentType),
      body: body,
    }).catch(() => {
      this.handleError(dispatch, types.failure);
    });

    this.dispatchResponse(dispatch, response, types);
  }

  static async delete(dispatch, url, types, body) {
    dispatch({ type: types.start });
    let response = await fetch(baseUrl() + url, {
      method: "DELETE",
      headers: headers(),
      body: body && JSON.stringify(body),
    }).catch(() => {
      this.handleError(dispatch, types.failure);
    });

    this.dispatchResponse(dispatch, response, types);
  }
  static async get(dispatch, url, types, queryParam) {
    dispatch({ type: types.start });
    if (!queryParam) queryParam = {};
    url = Service.formatUrl(baseUrl() + url, queryParam);
    let response = await fetch(url, {
      headers: headers(),
    }).catch(() => {
      this.handleError(dispatch, types.failure);
    });
    if (types.start === "GET_ROTA") {
      const newBlob = await response.blob();
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `Rota-${new Date()}.${"xlsx"}`);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
    }
    this.dispatchResponse(dispatch, response, types);
  }

  static async setEvents(dispatch, body, types) {
    dispatch({ type: types.start, paylaod: body });
  }

  static async setClient(dispatch, id, types) {
    dispatch({ type: types.start, payload: id });
  }

  static async dispatchResponse(dispatch, response, types) {
    try {
      response.ok
        ? response.status === 204 || types.success === "GET_ROTA_SUCCESS"
          ? dispatch({ type: types.success })
          : dispatch({ type: types.success, response: await response.json() })
        : dispatch({ type: types.failure, response: await response.json() });
    } catch (e) {
      return { error: e };
    }
  }

  static formatUrl(url, queryParams) {
    if (url.includes("?")) {
      console.warn(
        "Query Parameters should not be specified in URL string. Instead this should happen in the queryParams: object."
      );
    }

    url = new URL(url);

    for (let i in queryParams)
      if (Array.isArray(queryParams[i])) {
        queryParams[i].map((dateParam) =>
          url.searchParams.append(i, dateParam)
        );
      } else {
        if (queryParams[i]) url.searchParams.append(i, queryParams[i]);
      }

    return url;
  }
}

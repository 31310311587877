import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { getAllGuards } from "store/action/guardAction";
import { allocateShift } from "store/action/shiftAction";
import Grid from "components/DataTables";
import ShiftExperienceForm from "./shiftExperienceForm";
import Alert from "components/Alert";
import dayjs from "dayjs";

const AllocateForm = ({
  getAllGuards,
  guardReducer,
  shiftReducer,
  allocateShift,
}) => {
  const dayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [redirectFlag, setRedirectFlag] = useState(false);

  useEffect(() => {
    const queryParam = {};
    queryParam["free_guards"] = shiftReducer.date_array;
    queryParam["start_time"] = shiftReducer.time_array;
    queryParam["finish_time"] = shiftReducer.time_finish_array;
    if (!guardReducer.isFreeGuardUpToDate)
      getAllGuards(queryParam, "GET_FREE_GUARDS");
  }, [getAllGuards, guardReducer.isFreeGuardUpToDate, shiftReducer.date_array]);

  useEffect(() => {
    setRedirectFlag(shiftReducer.isAllocated);
  }, [shiftReducer.isAllocated]);

  const allocateObjectShift = (id) => {
    let allocateRequest = {};
    allocateRequest["guard_id"] = id;
    allocateRequest["status"] = "assigned";
    allocateRequest["id"] = shiftReducer.eventsList;
    allocateShift(allocateRequest);
  };

  const sendWhatsapp = (data) => {
    const num = parseInt(data.phone);
    const msg = shiftReducer?.site_details.map(
      (obj) =>
        `%0aSite name: ${obj?.site_name},${obj?.postal_code}%0a Date: ${
          dayArray[dayjs(obj?.start_date).day()]
        },${dayjs(obj?.start_date).format("DD-MM-YYYY")}%0a
      ${obj?.time}`
    );

    const name = data.full_name;
    window.open(`https://wa.me/44${num}?text=Dear ${name} please confirm the below Allocated shifts :%0a
  ${msg} %0a%0aKind regards ${sessionStorage
      .getItem("username")
      .substring(0, sessionStorage.getItem("username").lastIndexOf("@"))}.`);
  };
  const actionRenderer = ({ data }) => {
    return (
      <>
        <button
          className="btn btn-dark ms-2"
          onClick={() => {
            allocateObjectShift(data.id);
          }}
        >
          <i className="fa fa-user-plus">Allocate</i>
        </button>
        <button
          className="btn btn-success ms-2"
          onClick={() => {
            sendWhatsapp(data);
          }}
        >
          <i className="fa fa-whatsapp">Whatsapp</i>
        </button>
      </>
    );
  };
  const commentRenderer = ({ data }) => {
    return data.comment ? (
      <>
        <span
          className="comment-count"
          data-toggle="tooltip"
          title={data.comment}
        >
          <i className="fa fa-info-circle"></i>
        </span>
        <span>{data.full_name}</span>
      </>
    ) : (
      data.full_name
    );
  };
  const guardColumns = {
    columnDefs: [
      {
        headerName: "Staff Name",
        // field: "full_name",
        cellRendererFramework: commentRenderer,
        sortable: true,
        width: 190,
      },
      { headerName: "SIA Number", field: "sia_static_door" },
      { headerName: "SIA Expires", field: "sia_expiry", width: 190 },
      { headerName: "Work Locations", field: "work_locations" },
      { headerName: "Phone", field: "phone", width: 150 },
      { headerName: "Email", field: "email" },
      {
        headerName: "Actions",
        width: 190,
        cellRendererFramework: actionRenderer,
        pinned: "right",
      },
    ],
  };

  const siteDetailsColumns = {
    columnDefs: [
      {
        headerName: "SITE NAME",
        field: "site_name",
        sortable: true,
        width: 450,
      },
      { headerName: "START DATE", field: "start_date", width: 400 },
      { headerName: "TIMES", field: "time", width: 400 },
    ],
  };

  if (redirectFlag === true) {
    return <Redirect to={{ pathname: "/all-shifts" }} />;
  }
  return (
    <div className="col-12">
      <Alert />
      <div className="grid simple ">
        <div className="grid-title">
          <h3>
            <span className="semi-bold">
              <u>Allocate Shifts Here</u>
            </span>
          </h3>
        </div>
        <div className="grid-body ">
          <div
            id="example_wrapper"
            className="dataTables_wrapper form-inline"
            role="grid"
          >
            <h5 className="mt-4">
              {" "}
              Note: You are Assigning the following Shifts. Once Allocated
              they'll move to unconfirmed page.
            </h5>
          </div>
        </div>
      </div>

      {shiftReducer.site_details.length ? (
        <>
          <div className="">
            <h5 className="mt-4">Site Details</h5>
            <div className="col-12">
              <Grid
                columnDefs={siteDetailsColumns}
                rowData={shiftReducer.site_details}
                dataRetrieved={!guardReducer.isRetrieving}
                hideExtras={true}
              ></Grid>
            </div>
          </div>
        </>
      ) : (
        <h4 className="mt-4">Please Select A Site Before Assigning</h4>
      )}
      <ShiftExperienceForm
        shiftReducer={shiftReducer}
        allocateShift={allocateShift}
      />

      <div className="card ">
        <div className="card-header">
          <h4 className="">Select An Employee To Allocate</h4>
        </div>
        <div className="card-body">
          <Grid
            columnDefs={guardColumns}
            rowData={guardReducer.freeGuards}
            dataRetrieved={!guardReducer.isRetrieving}
          ></Grid>
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ guardReducer, shiftReducer }) => ({ guardReducer, shiftReducer }),
  {
    getAllGuards,
    allocateShift,
  }
)(AllocateForm);

import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createGuard, updateGuard } from "store/action/guardAction";
import { resetCloseModal } from "store/action/alerts";
import Alert from "components/Alert";

const GuardForm = ({
  createGuard,
  updateGuard,
  resetCloseModal,
  guardReducer,
  data,
}) => {
  const form = useRef(null);
  let close = useRef(null);

  const resetForms = () => {
    document.getElementById("newGuardForm").reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData(form.current);
    createGuard(formdata, "formdata");
  };

  useEffect(() => {
    if (guardReducer.closeModal) {
      close.click();
      resetForms();
      resetCloseModal();
    }
  }, [guardReducer.closeModal, resetCloseModal]);

  return (
    <div>
      <Alert />
      <form
        id="newGuardForm"
        ref={form}
        className="p-2"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="form-row guard-form-row mt-2 d-flex justify-content-between">
          <div className=" form-group guard-form-group mt-2">
            <label htmlFor="name" className="font-weight-bold">
              Full Name <span className="req">*</span> :
            </label>
            <input
              type="text"
              className="form-control"
              id="full_name"
              defaultValue={data.full_name}
              placeholder="Full Name"
              name="full_name"
              required
            />
          </div>
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="dob" className="font-weight-bold">
              Date Of Birth <span className="req">*</span> :
            </label>
            <input
              type="date"
              className="form-control"
              id="date_of_birth"
              defaultValue={data.date_of_birth}
              placeholder="Date Of Birth"
              name="date_of_birth"
              required
            />
          </div>
        </div>
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="email" className="font-weight-bold">
              Email:
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              defaultValue={data.email}
              placeholder="Email"
              name="email"
            />
          </div>
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="phone" className="font-weight-bold">
              Phone Number <span className="req">*</span> :
            </label>
            <input
              type="number"
              className="form-control"
              id="phone"
              defaultValue={data.phone}
              placeholder="Phone Number"
              name="phone"
              required
            />
          </div>
        </div>
        <div className="form-row guard-form-row d-flex">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="full_address" className="font-weight-bold">
              Full Address <span className="req">*</span> :
            </label>
            <textarea
              className="form-control"
              id="full_address"
              defaultValue={data.full_address}
              placeholder="Full Address"
              name="full_address"
              required
            />
          </div>
        </div>
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="guest" className="font-weight-bold">
              Guard Type:
            </label>
            <select
              type="text"
              className="form-control"
              id="guest"
              placeholder="Guest"
              name="guest"
              defaultValue={data.guest}
            >
              <option value="0">--Select Guard Type--</option>
              <option value="1">Sub Contractor</option>
              <option value="0">Permanent</option>
            </select>
          </div>
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="work_locations" className="font-weight-bold">
              Work Locations <span className="req">*</span> :
            </label>
            <input
              type="text"
              className="form-control"
              id="work_locations"
              defaultValue={data.work_locations}
              placeholder="Work Locations"
              name="work_locations"
              required
            />
          </div>
        </div>
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="personal_id_number" className="font-weight-bold">
              Personal Id Number:
            </label>
            <input
              type="text"
              className="form-control"
              id="personal_id_number"
              placeholder="PIN"
              defaultValue={data.personal_id_number}
              name="personal_id_number"
            />
          </div>
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="ni_number" className="font-weight-bold">
              NI Number:
            </label>
            <input
              type="text"
              className="form-control"
              id="ni_number"
              defaultValue={data.ni_number}
              placeholder="NI Number"
              name="ni_number"
            />
          </div>
        </div>
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="personal_id_number" className="font-weight-bold">
              SIA badge no.<span className="req">*</span> :
            </label>
            <input
              type="text"
              className="form-control"
              id="sia_static_door"
              defaultValue={data.sia_static_door}
              placeholder=" SIA badge no."
              name="sia_static_door"
              required
            />
          </div>
          {/* <div className="form-row guard-form-row">
            <div className=" col form-group guard-form-group mt-2  ">
              <label
                htmlFor="door_supervision_license_doc"
                className="font-weight-bold">
                Upload if Door Supervision licence:
              </label>
              <input
                type="file"
                className="form-control"
                id="door_supervision_license_doc"
                defaultValue={data.door_supervision_license_doc}
                onChange={e => setDoorFlag(!doorFlag)}
                placeholder="Door Supervision Licence"
                name="door_supervision_license_doc"
              />
            </div>
          </div> */}
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="sia_expiry" className="font-weight-bold">
              SIA Expiry <span className="req">*</span> :
            </label>
            <input
              type="date"
              className="form-control"
              id="sia_expiry"
              defaultValue={data.sia_expiry}
              placeholder="Expity Date"
              name="sia_expiry"
              required
            />
          </div>
        </div>
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="sia_cctv" className="font-weight-bold">
              Other licence no.1 :
            </label>
            <input
              type="text"
              className="form-control"
              id="sia_cctv"
              defaultValue={data.sia_cctv}
              placeholder="Other licence no.1"
              name="sia_cctv"
            />
          </div>
          {/* <div className=" col form-group guard-form-group mt-2  ">
            <label htmlFor="cctv_license_doc" className="font-weight-bold">
              Upload if CCTV licence:
            </label>
            <input
              type="file"
              className="form-control"
              id="cctv_license_doc"
              defaultValue={data.cctv_license_doc}
              placeholder="CCTV Licence"
              name="cctv_license_doc"
              onChange={e => setCctvFlag(!cctvFlag)}
            />
          </div> */}
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="cscs_license" className="font-weight-bold">
              Other licence no.2:
            </label>
            <input
              type="text"
              className="form-control"
              id="cscs_license"
              defaultValue={data.cscs_license}
              placeholder="Other licence no.2"
              name="cscs_license"
            />
          </div>
        </div>
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="brp_number" className="font-weight-bold">
              Passport / BRP Number <span className="req">*</span> :
            </label>
            <input
              type="text"
              className="form-control"
              id="brp_number"
              defaultValue={data.brp_number}
              placeholder="BRP Numner"
              name="brp_number"
              required
            />
          </div>
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="brp_expiry" className="font-weight-bold">
              Passport/BRP Expiry <span className="req">*</span> :
            </label>
            <input
              type="date"
              className="form-control"
              id="brp_expiry"
              defaultValue={data.brp_expiry}
              placeholder="BRP Date"
              name="brp_expiry"
              required
            />
          </div>
        </div>
        {/* <div className=" col form-group guard-form-group mt-2  ">
          <label htmlFor="brp_doc" className="font-weight-bold">
            Passport/BRP Document:
          </label>
          <input
            type="file"
            className="form-control"
            id="brp_doc"
            defaultValue={data.brp_doc}
            onChange={e => setBrpFlag(!brpFlag)}
            placeholder="BRP Document"
            name="brp_doc"
          />
        </div> */}
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="cctv_expiry" className="font-weight-bold">
              CCTV Expiry:
            </label>
            <input
              type="date"
              className="form-control"
              id="cctv_expiry"
              defaultValue={data.cctv_expiry}
              placeholder="CCTV Date"
              name="cctv_expiry"
            />
          </div>
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="houry_rate" className="font-weight-bold">
              Hourly Rate <span className="req">*</span> :
            </label>
            <input
              type="decimal"
              className="form-control"
              id="houry_rate"
              defaultValue={data.houry_rate}
              placeholder="Hourly Rate"
              name="houry_rate"
              required
            />
          </div>
          {/* <div className=" col form-group guard-form-group mt-2  ">
            <label htmlFor="cscs_license_doc" className="font-weight-bold">
              Upload if cscs licence:
            </label>
            <input
              type="file"
              className="form-control"
              id="cscs_license_doc"
              defaultValue={data.cscs_license_doc}
              onChange={e => setCscsFlag(!cscsFlag)}
              placeholder="CCTV Date"
              name="cscs_license_doc"
            />
          </div> */}
        </div>
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="employment_start" className="font-weight-bold">
              Employment Start Date <span className="req">*</span> :
            </label>
            <input
              type="date"
              className="form-control"
              id="employment_start"
              placeholder="Start Date"
              defaultValue={data.employment_start}
              name="employment_start"
              required
            />
          </div>
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="employment_finish" className="font-weight-bold">
              Employment Finish Date:
            </label>
            <input
              type="date"
              className="form-control"
              id="employment_finish"
              defaultValue={data.employment_finish}
              placeholder="Finish Date"
              name="employment_finish"
            />
          </div>
        </div>
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="sort_code" className="font-weight-bold">
              Sort Code: <span className="req">*</span> :
            </label>
            <input
              type="text"
              className="form-control"
              id="sort_code"
              defaultValue={data.sort_code}
              placeholder="Sort Rate"
              name="sort_code"
              required
            />
          </div>
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="account_number" className="font-weight-bold">
              Account Number: <span className="req">*</span> :
            </label>
            <input
              type="text"
              className="form-control"
              id="account_number"
              defaultValue={data.account_number}
              placeholder="Account Number"
              name="account_number"
              required
            />
          </div>
        </div>
        {/* <div className=" col form-group guard-form-group mt-2  ">
          <label htmlFor="proof_of_address" className="font-weight-bold">
            Proof Of Address:
          </label>
          <input
            type="file"
            className="form-control"
            id="proof_of_address"
            defaultValue={data.proof_of_address}
            placeholder="Proof Of Adderss"
            name="proof_of_address"
            onChange={e => setAddressFlag(!addressFlag)}
          />
        </div> */}
        {/* <div className="form-row guard-form-row"> */}
        {/* <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="reference" className="font-weight-bold">
              Reference :
            </label>
            <input
              type="file"
              className="form-control"
              id="reference"
              defaultValue={data.reference}
              placeholder="Reference"
              name="reference"
              onChange={e => setReferenceFlag(!referenceFlag)}
            />
          </div> */}
        {/* </div> */}
        <div className="form-row guard-form-row">
          <div className="form-group guard-form-group mt-2  ">
            <label htmlFor="ni_number" className="font-weight-bold">
              comments:
            </label>
            <textarea
              className="form-control"
              id="comment"
              placeholder="Comment"
              defaultValue={data.comment}
              name="comment"
            />
          </div>
        </div>
        {/* <div className=" col form-group guard-form-group mt-2  ">
          <label htmlFor="application_form" className="font-weight-bold">
            Application Form:
          </label>
          <input
            type="file"
            className="form-control"
            id="application_form"
            defaultValue={data.application_form}
            placeholder="Application Form"
            onChange={e => setAppformFlag(!appformFlag)}
            name="application_form"
          />
        </div> */}
        <hr />
        <div>Upload Documents:</div>
        <hr />
        <div className="form-row guard-form-row d-flex justify-content-between">
          <div className=" col form-group guard-form-group mt-2  ">
            <label htmlFor="profile_picture" className="font-weight-bold">
              Profile Picture:
            </label>
            <input
              type="file"
              className="form-control"
              id="profile_picture"
              defaultValue={data.profile_picture}
              placeholder="Profile Picture"
              name="profile_picture"
            />
          </div>
          <div className=" col form-group guard-form-group mt-2  ">
            <label htmlFor="docs" className="font-weight-bold">
              Documents
            </label>
            <input
              type="file"
              className="form-control"
              id="docs"
              defaultValue={data.cctv_license_doc}
              placeholder="CCTV Licence"
              name="docs"
              multiple={true}
            />
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-center mt-2">
          <button type="submit" className="btn btn-primary">
            {guardReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Processing...</span>
              </>
            ) : (
              <span>Save Employee</span>
            )}
          </button>
          <button
            type="button"
            className="btn btn-warning ms-2"
            data-bs-dismiss="modal"
            ref={(button) => (close = button)}
            onClick={() => resetForms()}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default connect(({ guardReducer }) => ({ guardReducer }), {
  createGuard,
  updateGuard,
  resetCloseModal,
})(GuardForm);

import React from "react";

const Modal = ({ title, children }) => {
	return (
		<div
			className="modal fade"
			id="commonModal"
			aria-labelledby="commonModalLabel"
			aria-hidden="true">
			<div className="modal-dialog  modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="commonModalLabel">
							{title}
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"></button>
					</div>
					<div className="modal-body">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;

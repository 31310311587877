import React, { useState, useEffect, lazy, Suspense } from "react";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import Spinner from "components/Spinner";
const AgGridReact = lazy(() => import("./grid"));

let api;
// const overlayLoadingTemplate =
// 	'<span class="ag-overlay-loading-center font-weight-bold">Please wait while your data is fetched</span>';
const overlayNoRowsTemplate =
  "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;\">This is a custom 'no rows' overlay</span>";

const Grid = ({
  columnDefs,
  rowData,
  isEditable,
  addData,
  removeData,
  updateData,
  children,
  dataRetrieved,
  deselectAll,
  firstDataRendered,
  hideExtras,
  exportAll,
  activeEmployees,
}) => {
  useEffect(() => {
    if (deselectAll) {
      api && api.deselectAll();
    }
  }, [deselectAll]);

  useEffect(() => {}, [activeEmployees]);

  const defaultColDef = {
    editable: isEditable,
    sortable: true,
    resizable: true,
  };

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    api = params.api;
    // gridApi = useRef(params.api);
    setGridApi(params.api);
  };

  const rowSelected = (data) => {
    data.node.selected ? addData(data.data) : removeData(data.data);
  };

  const handleCellValueChanged = (data) => {
    updateData(data.data);
  };

  function getParams() {
    return {
      suppressQuotes: false,
      columnSeparator: false,
      customHeader: "array",
      customFooter: "array",
      fileName: `${window.location.href.substr(
        window.location.href.lastIndexOf("/") + 1
      )}_${new Date()} `,
    };
  }

  function onBtnExport() {
    var params = getParams();
    delete params["customFooter"];
    delete params["customHeader"];
    if (params.suppressQuotes || params.columnSeparator) {
      alert(
        "NOTE: you are downloading a file with non-standard quotes or separators - it may not render correctly in Excel."
      );
    }
    gridApi.exportDataAsCsv(params);
  }

  const [filter, setFilter] = useState("");

  if (dataRetrieved) {
    if (rowData && rowData.length === 0) {
      return (
        <>
          {children}
          <p className="font-weight-bold text-center">No Data to display</p>
        </>
      );
    }

    return (
      <>
        <div className="d-flex justify-content-between pb-2 w-100">
          {!hideExtras && (
            <>
              <input
                className="form-control mr-2 filter-text-box"
                type="text"
                placeholder="Filter"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
              />
              {exportAll && (
                <button
                  className="btn btn-primary fa fa-download ms-2 text-white"
                  onClick={() => exportAll()}
                >
                  Download Details
                </button>
              )}
              <button
                className="btn btn-primary fa fa-download ms-2 text-white"
                onClick={() => onBtnExport()}
                data-toggle="tooltip"
                title="Click here to export as pdf!"
              >
                <span className="ms-2 text-white"> Download List</span>
              </button>

              {children}
            </>
          )}
        </div>

        <Suspense fallback={<Spinner />}>
          <div className="ag-theme-material border  mt-4 w-100">
            <AgGridReact
              rowData={rowData}
              quickFilterText={filter}
              onGridReady={onGridReady}
              {...columnDefs}
              rowSelection="multiple"
              rowDeselection="True"
              domLayout={"autoHeight"}
              rowMultiSelectWithClick="False"
              suppressRowClickSelection="True"
              onRowSelected={rowSelected}
              pagination={hideExtras ? "false" : "true"}
              onFirstDataRendered={firstDataRendered}
              paginationPageSize={15}
              onCellValueChanged={handleCellValueChanged}
              overlayNoRowsTemplate={overlayNoRowsTemplate}
              tooltipShowDelay={0}
              enableBrowserTooltips={true}
              tabToNextCell={() => gridApi.tabToNextCell}
              defaultColDef={defaultColDef}
            ></AgGridReact>
          </div>
        </Suspense>
      </>
    );
  } else {
    return <Spinner />;
  }
};

export default Grid;

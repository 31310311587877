import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { resetCloseModal } from "store/action/alerts";
import { createSite, updateSite } from "store/action/siteAction";
import { getAllClients } from "store/action/clientAction";
import Alert from "components/Alert";

const SiteForm = ({
  isUpdating,
  siteReducer,
  resetCloseModal,
  createSite,
  getAllClients,
  clientReducer,
  data,
  updateSite,
}) => {
  useEffect(() => {
    if (!clientReducer.isClientUpToDate) getAllClients();
  }, [getAllClients, clientReducer.isClientUpToDate]);

  const form = useRef(null);
  let close = useRef(null);

  const resetForms = () => {
    document.getElementById("newSiteForm").reset();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formdata = new FormData(form.current);
    formdata.append("client", clientReducer.clientId);
    isUpdating
      ? updateSite(data.id, formdata, "formdata")
      : createSite(formdata, "formdata");
  };

  useEffect(() => {
    if (siteReducer.closeModal) {
      close.click();
      resetForms();
      resetCloseModal();
    }
  }, [siteReducer.closeModal, resetCloseModal]);

  return (
    <div>
      <Alert />
      <form
        id="newSiteForm"
        ref={form}
        className="p-2"
        onSubmit={e => handleSubmit(e)}>
        <div className="form-row">
          <div className="form-group mt-2  ">
            <label htmlFor="sin" className="font-weight-bold">
              Site Identification Number (SIN) :
            </label>
            <input
              type="text"
              className="form-control"
              id="site_identification_number"
              defaultValue={data.site_identification_number}
              placeholder="Site Identification Number"
              name="site_identification_number"
            />
          </div>
          <div className="form-group mt-2  ">
            <label htmlFor="site_name" className="font-weight-bold">
              Site Name :<span className="req">*</span> :
            </label>
            <input
              type="text"
              className="form-control"
              id="site_name"
              defaultValue={data.site_name}
              placeholder="Site Name"
              name="site_name"
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group mt-2  ">
            <label htmlFor="address" className="font-weight-bold">
              Address :
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              defaultValue={data.address}
              placeholder="Address"
              name="address"
              required
            />
          </div>
          <div className="form-group mt-2  ">
            <label htmlFor="postal_code" className="font-weight-bold">
              Postal Code : <span className="req">*</span> :
            </label>
            <input
              type="text"
              className="form-control"
              id="postal_code"
              defaultValue={data.postal_code}
              placeholder="Postal Code"
              name="postal_code"
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group mt-2  ">
            <label htmlFor="full_address" className="font-weight-bold">
              Phone :
            </label>
            <input
              className="form-control"
              id="phone"
              defaultValue={data.phone}
              placeholder="Phone number"
              name="phone"
              type="number"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group mt-2  ">
            <label htmlFor="charge_rate" className="font-weight-bold">
              Charge Rate:
            </label>
            <input
              type="decimal"
              className="form-control"
              id="charge_rate"
              placeholder="Charge Rate"
              defaultValue={data.charge_rate}
              name="charge_rate"
              required
            />
          </div>
          <div className="form-group mt-2  ">
            <label htmlFor="start_date" className="font-weight-bold">
              Start Date :
            </label>
            <input
              type="date"
              className="form-control"
              id="start_date"
              defaultValue={data.start_date}
              placeholder="Start Date"
              name="start_date"
              required
            />
          </div>
          <div className="form-group mt-2  ">
            <label htmlFor="finish_date" className="font-weight-bold">
              Finish Date :
            </label>
            <input
              type="date"
              className="form-control"
              id="finish_date"
              defaultValue={data.finish_date}
              placeholder="Finish Date"
              name="finish_date"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <button type="submit" className="btn btn-primary">
            {siteReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Processing...</span>
              </>
            ) : isUpdating ? (
              <span>Update Site</span>
            ) : (
              <span>Save Site</span>
            )}
          </button>
          <button
            type="button"
            className="btn btn-warning ms-2"
            data-bs-dismiss="modal"
            ref={button => (close = button)}
            onClick={() => resetForms()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default connect(
  ({ siteReducer, clientReducer }) => ({ siteReducer, clientReducer }),
  {
    createSite,
    getAllClients,
    updateSite,
    resetCloseModal,
  }
)(SiteForm);

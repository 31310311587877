import React, { useEffect, useState } from "react";
import { setEvents } from "store/action/shiftAction";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import dayjs from "dayjs";

const ShiftGrid = ({
  shiftReducer,
  currentDate,
  mode,
  setEvents,
  filterText,
}) => {
  const [eventId, setEventId] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedTime, setSelectedTime] = useState([]);
  const [selectedFinishTime, setSelectedFinishTime] = useState([]);
  const [redirectFlag, setRedirectFlag] = useState(false);
  const [filteredShifts, setFilteredShifts] = useState(shiftReducer.shiftList);
  let total_hrs = 0;
  const [siteId, setSiteId] = useState(0);
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  useEffect(() => {
    if (shiftReducer.isAllocated) {
      setSelectedDate([]);
      setEventId([]);
    }
  }, [shiftReducer.isAllocated]);

  useEffect(() => {
    if (filterText != "") {
      setFilteredShifts(
        shiftReducer.shiftList?.filter((s) =>
          s.site_name.toLowerCase().includes(filterText.toLowerCase())
        )
      );
    } else {
      setFilteredShifts(shiftReducer.shiftList);
    }
  }, [filterText, shiftReducer.shiftList]);

  const getCurrentWeekDates = () => {
    let monday = "";
    const dateArray = [];
    let loopTill = 0;
    let dayOfWeek = currentDate.day();
    if (!dayOfWeek) dayOfWeek = 6;
    else dayOfWeek = dayOfWeek - 1;
    if (mode === 30) {
      monday = currentDate.startOf("month");
      loopTill = currentDate.endOf("month").date();
    } else {
      loopTill = 7;
      monday = currentDate.subtract(dayOfWeek, "day");
    }

    for (let i = 0; i < loopTill; i++) {
      dateArray.push(monday.add(i, "day").format("YYYY-MM-DD"));
    }
    return dateArray;
  };

  shiftReducer.shiftList.map(
    (data, i) => (total_hrs = total_hrs + data.total_hours)
  );

  const eventSelect = (
    e,
    siteName,
    postalCode,
    startDate,
    status,
    time,
    startTime,
    finishTime
  ) => {
    let eventsArray = [...eventId];
    let dateArray = [...selectedDate];
    let timeArray = [...selectedTime];
    let timeFinishArray = [...selectedFinishTime];
    let eventsObj = {
      events: [],
      site_details: [],
      date_array: [],
    };

    let site_obj = shiftReducer.site_details ? shiftReducer.site_details : [];
    if (eventsArray.includes(e.currentTarget.id)) {
      e.currentTarget.className = `pointer ${
        status === "unassigned"
          ? "calendarDiv"
          : status === "assigned"
          ? "allocatedDiv"
          : status === "cancelled"
          ? "cancelDiv"
          : "confirmedDiv"
      }`;
      const index = eventsArray.findIndex(
        (event) => event === e.currentTarget.id
      );
      const dateIndex = dateArray.findIndex((d) => d === e.currentTarget.title);
      dateArray.splice(dateIndex, 1);
      const timeIndex = timeArray.findIndex((t) => t === startTime);
      timeArray.splice(timeIndex, 1);
      const timeFinishIndex = timeFinishArray.findIndex(
        (t) => t === finishTime
      );
      timeFinishArray.splice(timeFinishIndex, 1);
      eventsArray.splice(index, 1);
    } else if (
      !selectedDate.includes(e.currentTarget.title) &&
      status === "unassigned"
    ) {
      eventsArray.push(e.currentTarget.id);
      dateArray.push(e.currentTarget.title);
      timeArray.push(startTime);
      timeFinishArray.push(finishTime);
      e.currentTarget.className = "pointer activeCard";
    } else if (status !== "unassigned") {
      eventsArray.push(e.currentTarget.id);
      dateArray.push(e.currentTarget.title);
      timeArray.push(startTime);
      timeFinishArray.push(finishTime);
      e.currentTarget.className = "pointer activeCard";
    }
    setSelectedDate(dateArray);
    setSelectedTime(timeArray);
    setSelectedFinishTime(timeFinishArray);
    setEventId(eventsArray);
    eventsObj.events = eventsArray;
    site_obj.push({
      site_name: siteName,
      postal_code: postalCode,
      start_date: startDate,
      time: time,
    });
    eventsObj.site_details = site_obj;
    eventsObj.date_array = dateArray;
    eventsObj.time_array = timeArray;
    eventsObj.time_finish_array = timeFinishArray;
    setEvents(eventsObj);
  };

  return redirectFlag ? (
    <Redirect
      to={{
        pathname: "/update-shift",
        state: { site: siteId, isupdating: true },
      }}
    />
  ) : (
    <div>
      <table className="table table-striped table-bordered">
        <thead className="table-sticky-header">
          <tr>
            <th style={{ minWidth: "100px" }}>Site Name</th>
            {getCurrentWeekDates().map((dateValue, i) => (
              <th style={{ minWidth: "100px" }} key={i}>
                <div className="">
                  <span>{dayArray[dayjs(dateValue).day()]}</span>
                  <br />
                  <span>{dayjs(dateValue).format("DD-MM-YYYY")}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredShifts.map(
            (data, i) =>
              data?.shifts.length > 0 && (
                <tr key={i}>
                  <td>
                    <h5 className="ms-2">{data.site_name}</h5>
                    <h6 className="mt-2 ">Postal Code : {data.postal_code}</h6>
                    <h6 className="mt-2 text-warning">
                      Total hours this week : {data.total_hours}
                    </h6>
                  </td>

                  {getCurrentWeekDates().map((dateValue, i) => (
                    <td key={i}>
                      {data?.shifts?.map(
                        (details) =>
                          dateValue.includes(details.start_date) && (
                            <div
                              className={`pointer ${
                                details.status === "unassigned"
                                  ? dayjs(dateValue).date() < dayjs().date()
                                    ? "calendarDiv blur"
                                    : "calendarDiv"
                                  : details.status === "assigned"
                                  ? dayjs(dateValue).date() < dayjs().date()
                                    ? "allocatedDiv blur"
                                    : "allocatedDiv"
                                  : details.status === "cancelled"
                                  ? dayjs(dateValue).date() < dayjs().date()
                                    ? "cancelDiv blur"
                                    : "cancelDiv"
                                  : dayjs(dateValue).date() < dayjs().date()
                                  ? "confirmedDiv blur"
                                  : "confirmedDiv"
                              }`}
                              onClick={(e) =>
                                eventSelect(
                                  e,
                                  details.site_name,
                                  details.postal_code,
                                  dateValue,
                                  details.status,
                                  details.scheduled_start_time +
                                    "-" +
                                    details.scheduled_finish_time,
                                  details.scheduled_start_time,
                                  details.scheduled_finish_time
                                )
                              }
                              onDoubleClick={(e) => {
                                setSiteId(details.id);
                                setRedirectFlag(true);
                              }}
                              id={details.id}
                              key={details.id}
                              title={dateValue}
                            >
                              <p>
                                {details.scheduled_start_time +
                                  "-" +
                                  details.scheduled_finish_time}
                              </p>
                              <p>{details.guard}</p>
                              {details.guard_pin && (
                                <p>({details.guard_pin})</p>
                              )}
                            </div>
                          )
                      )}
                    </td>
                  ))}
                </tr>
              )
          )}
        </tbody>
      </table>

      {(total_hrs === 0 || filteredShifts?.length === 0) && (
        <div className="text-center">No Shifts To Display</div>
      )}
    </div>
  );
};

export default connect(({ shiftReducer }) => ({ shiftReducer }), {
  setEvents,
})(ShiftGrid);

import Service from "api/service";

export const getAllGuards =
  (queryParams, action = "GET_ALL_GUARDS") =>
  dispatch => {
    Service.get(
      dispatch,
      "guards/",
      {
        start: action,
        success: action + "_SUCCESS",
        failure: action + "_FAILED",
      },
      queryParams
    );
  };

export const getExperiencedGuards =
  (queryParams, action = "GET_EXPERIENCED_GUARDS") =>
  dispatch => {
    Service.get(
      dispatch,
      "guards/",
      {
        start: action,
        success: action + "_SUCCESS",
        failure: action + "_FAILED",
      },
      queryParams
    );
  };

export const createGuard = (body, contentType) => dispatch => {
  Service.post(
    dispatch,
    "guards/",
    body,
    {
      start: "CREATE_GUARD",
      success: "CREATE_GUARD_SUCCESS",
      failure: "CREATE_GUARD_FAILED",
    },
    contentType
  );
};

export const sendEmail = (body, contentType) => dispatch => {
  Service.post(
    dispatch,
    "communication/",
    body,
    {
      start: "SEND_EMAIL",
      success: "SEND_EMAIL_SUCCESS",
      failure: "SEND_EMAIL_FAILED",
    },
    contentType
  );
};

export const updateGuard = (id, body, contentType) => dispatch => {
  Service.patch(
    dispatch,
    `guards/${id}/`,
    body,
    {
      start: "UPDATE_GUARD",
      success: "UPDATE_GUARD_SUCCESS",
      failure: "UPDATE_GUARD_FAILED",
    },
    contentType
  );
};

export const deleteGuard = id => dispatch => {
  Service.delete(dispatch, `guards/${id}/`, {
    start: "DELETE_GUARD",
    success: "DELETE_GUARD_SUCCESS",
    failure: "DELETE_GUARD_FAILED",
  });
};

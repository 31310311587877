import React from "react";
import Grid from "components/DataTables";

const ScheduledShifts = ({ data, isRetrieving, stats }) => {
  const { today_cancelled, today_confirmed, today_unassigned, today_assigned } =
    stats || {};
  const formatGuardName = params => {
    return params.data.guard || "Not Yet Assigned";
  };
  const shiftColumns = {
    columnDefs: [
      {
        headerName: "Site Name",
        field: "site",
        sortable: true,
      },
      {
        headerName: "Staff Name",
        field: "guard",
        valueFormatter: formatGuardName,
      },
      { headerName: "Start Time", field: "scheduled_start_time" },
      { headerName: "Finish Time", field: "scheduled_finish_time" },
      { headerName: "Status", field: "status" },
      { headerName: "Position", field: "position" },
    ],
  };

  return (
    <div className="card w-100 mt-5">
      <div className="card-header">
        <h4 className="text-center">Today's Scheduled Shifts</h4>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-around mb-5">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="fs-4 text-muted">Confirmed</span>
            <span className="fs-4">
              {today_confirmed}
              <span className="text-muted">
                {today_confirmed === 1 ? " Shift" : " Shifts"}
              </span>
            </span>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="fs-4 text-muted">Assigned</span>
            <span className="fs-4">
              {today_assigned}
              <span className="text-muted">
                {today_assigned === 1 ? " Shift" : " Shifts"}
              </span>
            </span>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="fs-4 text-muted">Unassigned</span>
            <span className="fs-4">
              {today_unassigned}
              <span className="text-muted">
                {today_unassigned === 1 ? " Shift" : " Shifts"}
              </span>
            </span>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="fs-4 text-muted">Cancelled</span>
            <span className="fs-4">
              {today_cancelled}
              <span className="text-muted">
                {today_cancelled === 1 ? " Shift" : " Shifts"}
              </span>
            </span>
          </div>
        </div>

        <Grid
          columnDefs={shiftColumns}
          rowData={data}
          dataRetrieved={!isRetrieving}></Grid>
      </div>
    </div>
  );
};

export default ScheduledShifts;
